<template>
  <div>
    <Header />
    <Search />
    <div class="ding">
      <div class="dingdan">
        <div class="ding_L">
          <div class="wenzi">
            <div class="wenzi_T">
              <div class="tijiao">{{ $t('common.shortmessage-002') }}</div>
              <div class="danhao">
                {{ $t('common.Serial_number') }}{{ istrue == '1' ? orderId : mergeOrderNo }}
              </div>
            </div>
            <div class="wenzi_B">
              请您在尽快<span class="red"></span>完成支付，否则订单会被自动取消
            </div>
          </div>
        </div>
        <div class="ding_R">
          <div class="yingfu">
            {{ $t('common.Amount_Payable:') }}<span class="price">{{ $n( sumPrice , 'currency' ) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wechat">
      <div class="wechat_pay">
        <div class="top">
          <div class="top-l">{{ $t('common.Wechat_Pay') }}</div>
          <div class="top-r" @click="fangshi">
            {{ $t('common.shortmessage-003') }}<i
              class="el-icon-arrow-right
"
            ></i>
          </div>
        </div>
        <div id="qrcode" ref="qrcode" class="img1 dis_f_c_c"></div>
        <div class="shuomiong">
          <i
            class="el-icon-full-screen
"
          ></i>
          {{ $t('common.shortmessage-013') }}
        </div>
      </div>
    </div>

    <!-- <Baoyou/> -->
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import { getPayCompanyInfo } from '@/api/company';
import { get, post } from '@/utils/request';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/buy_succeed/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
import { baseURL } from '@/utils/request';
import QRCode from 'qrcodejs2';
export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
  },
  data() {
    return {
      mergeOrderNo: null,
      sumPrice: 0,
      orderId: '',
      polling: '',
      istrue: '2',
    };
  },
  created() {
    this.sumPrice = this.$route.params.price;
    this.orderId = this.$route.query.orderId;
    this.istrue = this.$route.query.istrue;
    this.getdetail(this.$route.query.orderId);
  },
  mounted() {
    this.getorder();
    this.polling = setInterval(this.getorder, 2000);
  },
  destroyed() {
    clearInterval(this.polling);
  },
  methods: {
    fangshi() {
      this.$router.push({
        name: 'Zhuanzhang',
        params: {
          price: this.sumPrice,
        },
        query: {
          orderId: this.orderId,
          istrue: this.istrue,
        },
      });
    },

    getdetail(e) {
      if (this.istrue == '1') {
        this.weixin();
      } else {
        get('api/orderbasic/getorderbyid?orderId=' + e + '').then((res) => {
          this.mergeOrderNo = res.data.data.orderNo;
          this.weixin();
        });
      }
    },
    weixin() {
      if (this.istrue == '1') {
        get('api/wxPay/pay?mergeOrderNo=' + this.orderId + '&isTrue=1').then(
          (res) => {
            this.$nextTick(() => {
              const qrcode = new QRCode('qrcode', {
                width: 200, //二维码宽度
                height: 200, //二维码高度
                text: res.data.data.code_url, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr=uDKsQ4E00"
                colorDark: '#000', //颜色配置
                colorLight: '#fff',
              });
            });
          }
        );
      } else {
        get('api/wxPay/pay?orderNo=' + this.mergeOrderNo + '&isTrue=2').then(
          (res) => {
            this.$nextTick(() => {
              const qrcode = new QRCode('qrcode', {
                width: 200, //二维码宽度
                height: 200, //二维码高度
                text: res.data.data.code_url, //调用微信支付接口返回的微信特殊链接：例如"weixin://wxpay/bizpayurl?pr=uDKsQ4E00"
                colorDark: '#000', //颜色配置
                colorLight: '#fff',
              });
            });
          }
        );
      }
    },
    getorder() {
      if (this.istrue == '1') {
        get(
          'api/wxPay/queryOrderStatus?mergeOrderNo=' +
            this.orderId +
            '&isTrue=1'
        ).then((res) => {
          if (res.data.msg == '1') {
            //支付成功
            this.$message.success({
              message: this.$t('message.Payment_success'),
            });
            this.$router.push({
              name: 'Buy_succeed',
            });
          }
        });
      } else {
        get(
          'api/wxPay/queryOrderStatus?mergeOrderNo=' +
            this.mergeOrderNo +
            '&isTrue=2'
        ).then((res) => {
          if (res.data.msg == '1') {
            //支付成功
            this.$message.success({
              message: this.$t('message.Payment_success'),
            });
            this.$router.push({
              name: 'Buy_succeed',
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wechat {
  widows: 100%;
  height: 400px;
  background: #f5f5f5;
  .wechat_pay {
    width: 840px;
    height: 100%;
    background: #fff;
    margin: auto;
    padding: 20px 30px;
    box-shadow: 0px 0px 10px #999;
    .top {
      display: flex;
      justify-content: space-between;
      .top-l {
        font-size: 25px;
      }
      .top-r {
        cursor: pointer;
      }
    }
    .img1 {
      margin-left: 300px;
      margin-top: 30px;
    }
    .shuomiong {
      width: 200px;
      height: 40px;
      background: #f66;
      color: #fff;
      line-height: 40px;
      text-align: center;
      margin-left: 300px;
      margin-top: 20px;
    }
  }
}
</style>

<style lang="scss" scoped>
.ding {
  width: 100%;
  background: #f5f5f5;

  .dingdan {
    width: 1240px;
    height: 123px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ding_L {
      display: flex;

      .erweima {
        width: 95px;
        height: 90px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .wenzi {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        .wenzi_T {
          font-size: 16px;
          display: flex;

          .tijiao {
            color: #3e3e3e;
          }

          .danhao {
            color: #282828;
          }
        }

        .wenzi_B {
          font-size: 14px;
          margin-top: 15px;
          color: #3f3f3f;

          .red {
            color: #e41412;
          }
        }
      }
    }

    .ding_R {
      display: flex;
      flex-direction: column;

      .yingfu {
        color: #333;
        font-size: 16px;

        .price {
          font-size: 16px;
          color: #e53739;
        }
      }

      .detail {
        font-size: 14px;
        color: #1b50fe;
        margin-top: 15px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
